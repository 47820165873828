import React from "react";
import classes from "./LeftSideMenu.module.sass";
import { menuLinks } from "./constants";
import { MenuItem } from "./components/menuItem/MenuItem";

export const LeftSideMenu = () => {
  return (
    <div className={classes.Container}>
      {menuLinks.map((link) => (
        <MenuItem link={link} key={link.id} />
      ))}
    </div>
  );
};
