import { useState } from "react";
import { ParkingInterface } from "./../../../common/interfaces/interfaces";

const initialValue: ParkingInterface[] = [
  {
    innerId: 1,
    address: "Гаврилова 100, Краснодар",
    lat: "",
    lng: "",
    capacity: 100,
  },
  {
    innerId: 2,
    address: "Чекистов 12, Краснодар",
    lat: "",
    lng: "",
    capacity: 50,
  },
  {
    innerId: 3,
    address: "Тамбасова 4, Санкт-Петербург",
    lat: "",
    lng: "",
    capacity: 50,
  },
  {
    innerId: 4,
    address: "Дальневосточный проспект 18, Санкт-Петербург",
    lat: "",
    lng: "",
    capacity: 50,
  },
  {
    innerId: 5,
    address: "Народного Опоплчения 147, Санкт-Петербург",
    lat: "",
    lng: "",
    capacity: 50,
  },
  {
    innerId: 6,
    address: "Гагарина 20, Санкт-Петербург",
    lat: "",
    lng: "",
    capacity: 50,
  },
//   {
//     innerId: 7,
//     address: "Детская 10, Санкт-Петербург",
//     lat: "",
//     lng: "",
//     capacity: 50,
//   },
//   {
//     innerId: 8,
//     address: "Чекистов 27, Санкт-Петербург",
//     lat: "",
//     lng: "",
//     capacity: 50,
//   },
//   {
//     innerId: 9,
//     address: "проезд Репина 12, Краснодар",
//     lat: "",
//     lng: "",
//     capacity: 50,
//   },
//   {
//     innerId: 10,
//     address: "Московский проспект Санкт-Петербург",
//     lat: "",
//     lng: "",
//     capacity: 50,
//   },
];

export const useParking = () => {
  const [parkingList] = useState<ParkingInterface[]>(initialValue);

  return { parkingList };
};
