import React from "react";
import classes from "./ParkingItem.module.sass";
import { ParkingInterface } from "../../../../common/interfaces/interfaces";
import nav from "./../../../../assets/images/icons/nav.svg";
import gauge from "../../../../assets/images/icons/gauge.svg";
import caret_right from "../../../../assets/images/icons/caret_right.svg";
import { Status } from "../../../../common/components/status/Status";

type Props = {
  parking: ParkingInterface;
};

export const ParkingItem = ({ parking }: Props) => {
  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <div className={classes.ParkingInfo}>
          <div className={classes.InParkingInfoWrapper}>
            <div className={classes.ParkingName}>
              Парковка #{parking.innerId}
            </div>
            <div className={classes.ParkingAddress}>
              <img src={nav} alt="Адрес парковки" />
              {parking.address}
            </div>
          </div>
          <Status
            status
            title={{
              positive: "Парковка активна",
              negative: "Парковка неактивна",
            }}
          />
        </div>
      </div>

      {/* changeable */}
      <div className={classes.PlacesBlock}>
        <div className={classes.ForBorder}>
          <div className={classes.PlacesAvailable}>
            Доступно парковочных мест
            <div className={classes.Quantity}>20</div>
          </div>
        </div>
        <div className={classes.ForBorder}>
          <div className={classes.PlacesInUse}>
            Занято парковочных мест
            <div className={classes.Quantity}>20</div>
          </div>
        </div>
      </div>

      <div className={classes.BarrierStatusBlock}>
        <div className={classes.ForBorderBarrier}>
          <div className={classes.BarrierBlock}>
            Состояние шлагбаума выезд
            <Status status title={{ positive: "Открыт", negative: "Закрыт" }} />
          </div>
        </div>
        <div className={classes.ForBorderBarrier}>
          <div className={classes.BarrierBlock}>
            Состояние шлагбаума въезд
            <Status
              status={false}
              title={{ positive: "Открыт", negative: "Закрыт" }}
            />
          </div>
        </div>
      </div>

      <div className={classes.RevenueBlock}>
        <div className={classes.RevenueItem}>
          Выручка сегодня<div className={classes.Amount}>2700 руб</div>
        </div>
        <div className={classes.RevenueItem}>Выручка Март 2024</div>
        <div className={classes.Amount}>2700 руб</div>
      </div>

      {/* changeable */}

      <div className={classes.Footer}>
        <div className={classes.InFooterWrapper}>
          <div className={classes.InFooterWrapper2}>
            <img src={gauge} alt="Состояние устройств" />
            Состояние устройств
          </div>
          <img
            className={classes.DevicesIcon}
            src={caret_right}
            alt="Перейти"
          />
        </div>
      </div>
    </div>
  );
};
