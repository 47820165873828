import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PARKING_ROUTE } from "../../../../constants";

export const usePageHeader = () => {
  const location = useLocation();
  const [part1, setPart1] = useState<string>("Text 1");
  const [part2, setPart2] = useState<string>("text 2");

  useEffect(() => {
    switch (location.pathname) {
      case PARKING_ROUTE:
        setPart1('Наши')
        setPart2('Парковки')
        break;

      default:
        break;
    }
  }, [location]);

  return { part1, part2 };
};
