import React from "react";
import classes from "./Bar.module.sass";
import logo from "../../../assets/images/logo.svg";

export const Bar = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.LeftSideContainer}>
        <div className={classes.Logo}>
          <img src={logo} />
        </div>
        <div className={classes.TextLogo}>Ваш логотип</div>
      </div>
      <div className={classes.Title}>
        Система автоматизированных парковочных комплексов
      </div>
    </div>
  );
};
