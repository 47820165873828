import React from "react";
import { useParking } from "./hooks/useParking";
import { ParkingItem } from "./components/parkingItem/ParkingItem";
import classes from "./Parking.module.sass";
import { PageHeader } from "../../common/components/pageHeader/PageHeader";
import { Pagination } from "../../common/components/pagination/Pagination";

export const Parking = () => {
  const { parkingList } = useParking();

  return (
    <div className={classes.Container}>
      <PageHeader length={parkingList.length} />

      <div className={classes.ListContainer}>
        {parkingList.map((parking) => (
          <ParkingItem parking={parking} key={parking.innerId} />
        ))}
      </div>
      <Pagination
        totalCount={50}
        setOffset={() => {}}
        offset={0}
        limit={6}
        currentLength={parkingList.length}
      />
    </div>
  );
};
