import React from "react";
import classes from "./Status.module.sass";
import green_point from "../../../assets/images/icons/green_point.svg";
import red_point from "../../../assets/images/icons/red_point.svg";
import caret_down from "../../../assets/images/icons/caret_down.svg";
import { useStatus } from "./hooks/useStatus";

type Props = {
  status: boolean;
  title: { positive: string; negative: string };
};

export const Status = ({ status, title }: Props) => {
  return (
    <div className={status ? classes.Container : classes.ContainerNegative}>
      <img src={status ? green_point : red_point} alt="Цвет статуса" />
      {status ? title.positive : title.negative}
      {/* <img src={caret_down} alt="Развернуть" /> */}
    </div>
  );
};
