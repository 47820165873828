import React from "react";
import classes from "./Input.module.sass";

type Props = {
  type: string;
  value: string;
  placeHolder: string;
  onChangeHandler: (value: string, fieldName:string)=>void
  fieldName: string
};

export const Input = ({ type, value, placeHolder, onChangeHandler, fieldName }: Props) => {
  return (
    <input
        type={type}
      className={classes.Container}
      value={value}
      placeholder={placeHolder}
      onChange ={(e)=>onChangeHandler(e.target.value,fieldName)}
    />
  );
};
