import React from "react";
import classes from "./AppWrapper.module.sass";
import { LeftSideMenu } from "../../common/components/leftSideMenu/LeftSideMenu";
import { Bar } from "../../common/components/bar/Bar";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const AppWrapper = ({ children }: Props) => {
  return (
    <div className={classes.Container}>
      <Bar />
      <div className={classes.ContentContainer}>
        {" "}
        <LeftSideMenu />
        {children}
      </div>
    </div>
  );
};
