import React from "react";
import classes from "./PageHeader.module.sass";
import { usePageHeader } from "./hooks/usePageHeader";
import blue_cone from '../../../assets/images/icons/blue_cone.svg'

type Props = {
  length: number;
};

export const PageHeader = ({ length }: Props) => {
  const { part1, part2 } = usePageHeader();

  return (
    <div className={classes.Container}>
      <span className={classes.Part1}>{part1}</span>
      
      <span className={classes.Part2}>{part2}</span>
      <div className={classes.LengthBlock}>
        <img src={blue_cone}/> 
        {length} парковок</div>
    </div>
  );
};
