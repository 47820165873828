import React from "react";
import classes from "./Login.module.sass";
import logo from "../../assets/images/logo.svg";
import { Button } from "../../common/components/button/Button";
import { Input } from "../../common/components/input/Input";
import { useLogin } from "./hooks/useLogin";

export const Login = () => {
  const { credentials, inputHandler } = useLogin();

  return (
    <div className={classes.Container}>
      <div className={classes.ImageBlock}></div>
      <div className={classes.LoginBlock}>
        <img className={classes.Logo} src={logo} alt="Логотип" />
        <div className={classes.Title}>Вход в систему</div>
        <div className={classes.InLoginWrapper}>
          <Input
            fieldName={"login"}
            type={"text"}
            value={credentials.login}
            placeHolder="Введите логин"
            onChangeHandler={inputHandler}
          />
          <Input
            fieldName={"password"}
            type={"password"}
            value={credentials.password}
            placeHolder="Введите пароль"
            onChangeHandler={inputHandler}
          />
          <Button text={"Войти"} />
        </div>
      </div>
    </div>
  );
};
