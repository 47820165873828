import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { Protected } from "./common/components/Protected";
import { Users } from "./pages/users/Users";
import { Sales } from "./pages/reports/sales/Sales";
import { BarrierLogs } from "./pages/reports/barrier_logs/BarrierLogs";
import { Parking } from "./pages/parking/Parking";
import { Passes } from "./pages/passes/Passes";
import { Settings } from "./pages/settings/Settings";
import { WhiteList } from "./pages/lists/white_list/WhiteList";
import { BlackList } from "./pages/lists/black_list/BlackList";
import { ServiceList } from "./pages/lists/service_list/ServiceList";
import {
  BARRIER_LOGS_ROUTE,
  BLACK_LIST_ROUTE,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  PARKING_ROUTE,
  PASSES_ROUTE,
  ROLES_ROUTE,
  SALES_ROUTE,
  SERVICE_LIST_ROUTE,
  SETTINGS_ROUTE,
  USERS_ROUTE,
  WHITE_LIST_ROUTE,
} from "./constants";
import { Roles } from "./pages/roles/Roles";
import { Login } from "./pages/login/Login";

function App() {
  return (
    <Routes>
      <Route path={LOGIN_ROUTE} element={<Login />} />
      <Route element={<Protected />}>
        <Route path={MAIN_ROUTE} element={<Dashboard />} />
        <Route path={USERS_ROUTE} element={<Users />} />
        <Route path={ROLES_ROUTE} element={<Roles />} />
        <Route path={SALES_ROUTE} element={<Sales />} />
        <Route path={BARRIER_LOGS_ROUTE} element={<BarrierLogs />} />
        <Route path={PARKING_ROUTE} element={<Parking />} />
        <Route path={PASSES_ROUTE} element={<Passes />} />
        <Route path={SETTINGS_ROUTE} element={<Settings />} />
        <Route path={WHITE_LIST_ROUTE} element={<WhiteList />} />
        <Route path={BLACK_LIST_ROUTE} element={<BlackList />} />
        <Route path={SERVICE_LIST_ROUTE} element={<ServiceList />} />
      </Route>
    </Routes>
  );
}

export default App;
