import React from "react";
import { MenuLink } from "../../interfaces";
import classes from "./MenuItem.module.sass";
import { MenuSubItem } from "./components/menuSubItem/MenuSubItem";
import { useMenuItem } from "./hooks/useMenuItem";
import menuArrow from "../../../../../assets/images/menuArrow.svg";

type Props = {
  link: MenuLink;
};

export const MenuItem = ({ link }: Props) => {
  const { expanded, toggle, navigate, location } = useMenuItem();

  return (
    <div>
      <div
        className={
          location.pathname === link.link ? classes.LinkActive : classes.Link
        }
        onClick={() => {
          link.link ? navigate(link.link) : toggle();
        }}
      >
        {link.name} {location.pathname === link.link && <img className = {classes.MenuArrow} src={menuArrow} />}
      </div>
      {expanded &&
        link.subLinks.map((subLink) => (
          <MenuSubItem key={subLink.id} subLink={subLink} />
        ))}
    </div>
  );
};
