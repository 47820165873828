import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useMenuItem = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()

  const toggle = () => {
    setExpanded((prev) => !prev);
  };

  return { expanded, toggle, navigate, location };
};
