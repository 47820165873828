import { useState, useEffect } from 'react'

const usePagination = (totalCount: number, currentLength: number, limit: number, offset: number, updateList?: (limit: number, offset: number) => void) => {
    const [backwardActive, setBackwardActive] = useState(false)
    const [forwardActive, setForwardActive] = useState(false)



    useEffect(() => {
        if ((totalCount - offset - currentLength) > 0) {
            setForwardActive(true)
        } else {
            setForwardActive(false)
        }
        if ((currentLength + offset - limit) <= 0) {
            setBackwardActive(false)
        } else {
            setBackwardActive(true)
        }
        updateList &&  updateList(limit, offset)
    }, [totalCount, currentLength, limit, offset])

    return { forwardActive, backwardActive }
}
export default usePagination

