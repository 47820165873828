import { Navigate, Outlet } from 'react-router-dom'
import React from 'react'

import { useSelector } from 'react-redux'
import { AppWrapper } from '../../hoc/appWrapper/AppWrapper'
// import { selectAuthToken } from '../../store/slices/authSlice'

export const Protected = () => {
  // const token = useSelector(selectAuthToken)
  // const username = localStorage.getItem('username')

  // if (token || username) {
    return (
      <AppWrapper>
        <Outlet />
      </AppWrapper>
    )
  // } else {
  //   return <Navigate to="/login" />
  // }
}
