export const MAIN_ROUTE = "/mock";
export const LOGIN_ROUTE = "/login";
export const USERS_ROUTE = "/users";
export const ROLES_ROUTE = "/roles";
export const SALES_ROUTE = "/sales";
export const BARRIER_LOGS_ROUTE = "/barrier_logs";
export const PARKING_ROUTE = "/";
export const PASSES_ROUTE = "/passes";
export const SETTINGS_ROUTE = "/settings";
export const WHITE_LIST_ROUTE = "/white_list";
export const BLACK_LIST_ROUTE = "/black_list";
export const SERVICE_LIST_ROUTE = "/service_list";
