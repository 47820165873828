import { useState } from "react";
import { LoginCredentials } from "../../../common/interfaces/interfaces";

const initialValue = {
  login: "",
  password: "",
};
export const useLogin = () => {
  const [credentials, setCredentials] =
    useState<LoginCredentials>(initialValue);

  const inputHandler = (value: string, fieldName: string) => {
    setCredentials((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return { credentials, inputHandler };
};
