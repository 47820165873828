import React from "react";
import { MenuSubLnk } from "../../../../interfaces";
import classes from "./MenuSubItem.module.sass";
import { useMenuSubItem } from "./hooks/useMenuSubItem";
import menuArrow from "../../../../../../../assets/images/menuArrow.svg";

type Props = {
  subLink: MenuSubLnk;
};

export const MenuSubItem = ({ subLink }: Props) => {
  const { navigate, location } = useMenuSubItem();

  return (
    <div
      className={location.pathname !== subLink.link ?  classes.Link : classes.LinkActive}
      onClick={() => {
        navigate(subLink.link);
      }}
    >
      {subLink.name}  {location.pathname === subLink.link && <img className = {classes.MenuArrow} src={menuArrow} />}
    </div>
  );
};
