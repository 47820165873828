import {
  BARRIER_LOGS_ROUTE,
  BLACK_LIST_ROUTE,
  PARKING_ROUTE,
  PASSES_ROUTE,
  ROLES_ROUTE,
  SERVICE_LIST_ROUTE,
  USERS_ROUTE,
  WHITE_LIST_ROUTE,
} from "../../../constants";
import { SALES_ROUTE } from "./../../../constants";

export const menuLinks = [
  {
    id: 1,
    name: "Пользователи",
    link: USERS_ROUTE,
    subLinks: [],
  },
  {
    id: 2,
    name: "Парковки",
    link: PARKING_ROUTE,
    subLinks: [],
  },
  {
    id: 3,
    name: "Отчеты",
    link: "",
    subLinks: [
      { id: 1, name: "Продажи", link: SALES_ROUTE },
      { id: 2, name: "Журнал шлагбаумов", link: BARRIER_LOGS_ROUTE },
    ],
  },
  { id: 4, name: "Абонементы", link: PASSES_ROUTE, subLinks: [] },
  {
    id: 5,
    name: "Списки",
    link: "",
    subLinks: [
      { id: 1, name: "Черный список", link: BLACK_LIST_ROUTE },
      { id: 2, name: "Белый список", link: WHITE_LIST_ROUTE },
      { id: 2, name: "Служебный список", link: SERVICE_LIST_ROUTE },
    ],
  },
  { id: 6, name: "Роли и права", link: ROLES_ROUTE, subLinks: [] },
];
