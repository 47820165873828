import React from "react";
import classes from "./Button.module.sass";

type Props = {
  text: string;
};

export const Button = ({ text }: Props) => {
  return <button className={classes.Container}>{text}</button>;
};
