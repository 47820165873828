import React from 'react'
import classes from './Pagination.module.sass'
import usePagination from './hooks/usePagination'

interface PaginationProps {
  totalCount: number
  setOffset: (value: number) => void
  offset: number
  limit: number
  currentLength: number,
  updateList?: (limit: number, offset: number) => void
}

 export const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  currentLength,
  limit,
  offset,
  setOffset,
  updateList
}) => {

  const { forwardActive, backwardActive } = usePagination(totalCount, currentLength, limit, offset, updateList)

  return (
    <div className={classes.PaginationBlock}>
 
      <button      
        className={classes.PaginationButton}
        onClick={() => setOffset(offset + limit)}
      >
      
      </button>
    </div>
  )
}

